import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import VueCarousel from 'vue-carousel';

// call axios
import axios from "axios";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

// import vue-social-sharing
var SocialSharing = require("vue-social-sharing");
Vue.use(SocialSharing);
// import laravel-vue-pagination
Vue.component("pagination", require("laravel-vue-pagination"));
// import vuelidate
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);
// import Coockies
Vue.use(require("vue-cookies"));
Vue.$cookies.config("30d");
// import vue-notifiction
import Notifications from "vue-notification";
Vue.use(Notifications);

//
// Vue.use(VueCarousel);
Vue.config.silent = true;
// compare
Vue.mixin({
  data: function() {
    return {
      icon: 0,
      isDisable: 0,
      isLoading: true,
      filreseller: "",
      fildata: "",
      filtext: "",
      filminutes: "",
      filsimNetwork: "",
      filnetwork: "",
      filcolor: "",
      filstorage: "",
      filupfront: "",
      filmonthly: "",
      search: "",
      upfrontCostArray: [50, 100, 150, 200, 350],
      monthlyCostArray: [
        10,
        20,
        30,
        40,
      ],
      dataMBArray: [500],
      dataGBArray: [1, 4, 8, 20, 50],
      minutesArray: [100, 300, 600, 2000],
      textArray: [200, 4000],
      compareF(x) {
        if (window.$cookies.get("pro1") == null) {
          window.$cookies.set("pro1", x, "30d");
        } else if (window.$cookies.get("pro2") == null) {
          window.$cookies.set("pro2", x, "30d");
          window.location.href = "/compare";
        } else if (window.$cookies.get("pro3") == null) {
          window.$cookies.set("pro3", x, "30d");
          window.location.href = "/compare";
        } else {
          window.alert("you can make compare bettwen 3 items only");
          // this.$notify({
          //   group: "foo",
          //   type: "error",
          //   title: "Wrong message",
          //   text: "you can make compare bettwen 3 items only"
          // });
          window.location.href = "/compare";
        }
      }
    };
  }
});

// -----------
const devInstance = createInstance("https://app.contractsexpert.co.uk/api/");
const urlLink = createInstance("https://contractsexpert.co.uk/apis/");
function createInstance(baseURL) {
  return axios.create({
    baseURL
  });
}
Vue.prototype.$http = devInstance;
Vue.prototype.$urlmain = urlLink;
Vue.use(require("vue-moment")); //for format date
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

// brands
// networks
// categories
// blogs
// blog_categories
// colors
// products
// offers
// resellers
