<template>
  <div class="blogs-page">
    <PageLoader />
    <div class="blog-area-wrapper pt-5 pb-5">
      <div class="container box_shadow bg-white mt-4 p-5">
        <div class="row ">
          <div class="col-lg-12 order-first order-lg-last">
            <h1 class="text-center">404 page not found</h1>
            <div class="blog-wrapper-inner">
              <div class="single-blogg-item mb-30">
                <div class="blogg-content text-center m-5">
                 <router-link to="/" class="p-4">
                    Go to home page
                 </router-link>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "pageNotFound",
  data() {
  },
  mounted() {
   window.scrollTo(0, 0)
  }
};
</script>