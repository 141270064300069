import Vue from "vue";
import VueRouter from "vue-router";
import NotFound from './../components/units/404.vue';


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "Home" */ "../views/pages/home/Home.vue")
  },
  {
    path: "/about",
    name: "aboutUs",
    component: () =>
      import(/* webpackChunkName: "aboutUs" */ "../views/pages/about/index.vue")
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/pages/contact/index.vue"
      )
  },
  {
    path: "/terms-of-use",
    name: "TermsOfUse",
    component: () =>
      import(
        /* webpackChunkName: "TermsOfUse" */ "../views/pages/terms_and_conditions/index.vue"
      )
  },
  {
    path: "/privacy_policy",
    name: "privacyPolicy",
    component: () =>
      import(
        /* webpackChunkName: "privacyPolicy" */ "../views/pages/privacy_policy/index.vue"
      )
  },
  {
    path: "/compare",
    name: "compare",
    component: () =>
      import(
        /* webpackChunkName: "compare" */ "../views/pages/compare/index.vue"
      )
  },
   {
    path: "/guides",
    name: "guides",
    component: () =>
      import(/* webpackChunkName: "guides" */ "../views/pages/blogs/index.vue")
  },
  {
    path: "/guides/:id",
    name: "guidesInternal",
    component: () =>
      import(
        /* webpackChunkName: "guidesInternal" */ "../views/pages/blogs/internal/index.vue"
      )
  },
  // {
  //   path: "/products",
  //   name: "products",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "products" */ "../views/pages/products/index.vue"
  //     )
  // },
  {
    path: "/products/:id",
    name: "productsInternal",
    component: () =>
      import(
        /* webpackChunkName: "productsInternal" */ "../views/pages/products/internal/index.vue"
      )
  },
  // {
  //   path: "/categories",
  //   name: "categories",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "categories" */ "../views/pages/categories/index.vue"
  //     )
  // },
  {
    path: "/categories/:id",
    name: "categoriesinternal",
    component: () =>
      import(
        /* webpackChunkName: "categoriesinternal" */ "../views/pages/categories/internal/index.vue"
      )
  },
  {
    path: "/categories/:id/:brand",
    name: "brandChild",
    component: () =>
      import(
        /* webpackChunkName: "brandChild" */ "../views/pages/brands/internal/index.vue"
      )
  },
  {
    path: "/categories/:id/:brand/:product",
    name: "productChild",
    component: () =>
      import(
        /* webpackChunkName: "productChild" */ "../views/pages/products/internal/index.vue"
      )
  },
  {
    path: "/networks",
    name: "networks",
    component: () =>
      import(
        /* webpackChunkName: "networks" */ "../views/pages/networks/index.vue"
      )
  },
  {
    path: "/search",
    name: "search",
    component: () =>
      import(
        /* webpackChunkName: "search" */ "../views/pages/search/index.vue"
      )
  },
  {
    path: "/networks/:id",
    name: "networksinternal",
    component: () =>
      import(
        /* webpackChunkName: "networksinternal" */ "../views/pages/networks/internal/index.vue"
      )
  },
  { path: '*', component: NotFound }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
