<template>
  <div class="container-fluid">
    <div class="row text-center">
      <footer class="container-fluid bg_footer pt-4 footer">
        <div class="row text-center d-flex align-items-center">
            <ul class="list-group list-group-horizontal-sm m-auto pb-4 col-md-12 d-flex justify-content-center">
                <li class="list-group-item" v-for="(page, i) in pages" :key="page.title + i">
                    <router-link
                      :to="'/' + page.slug"
                      tag="a"
                      >{{ page.title }}
                    </router-link>
                </li>
                <li
                  class="list-group-item"
                  v-for="(info, i) in informations"
                  :key="i"
                >
                  <router-link
                    :to="'/categories/' + info.slug"
                    tag="a"
                    >{{ info.title }}</router-link
                  >
                </li>
            </ul>
            <p class=" m-auto col-md-12">{{settings.name}} is operated and owned by {{settings.company_name}}© 2020. All rights reserved. </p>
            <p class=" m-auto pb-4 col-md-12">{{settings.company_name}} is registered in England with Company No. {{settings.mobile}}. {{settings.address}}. </p>
        </div>
    </footer>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import { email, required } from "vuelidate/lib/validators";
export default {
  name: "FooterComp",
  data() {
    return {
      email: "",
      output: "",
      stat: "",
      countform: 0,
      settings: [],
      pages: [],
      informations: []
    };
  },
  validations: {
    email: {
      required,
      email,
      inUnique(value) {
        if (value === "") return true;

        var email_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;

        return new Promise(resolve => {
          setTimeout(() => {
            resolve(email_regex.test(value));
          }, 350 + Math.random() * 300);
        });
      }
    }
  },
  mounted() {
    this.getSetting();
    this.getfooterpages();
    this.loading = true;
    this.$urlmain
      .get("category-footer.json")
      .then(response => {
        this.informations = response.data.data;
        response => (this.informations = response.data.data);
      })
      .catch(error => console.log(error))
      .finally(() => (this.loading = false));
  },
  methods: {
    formSubmit(e) {
      if (this.countform < 2) {
        this.countform++;
        this.$v.$touch();
        e.preventDefault();
        let currentObj = this;
        this.$urlmain
          .post("subscribe?email=" + this.email)
          .then(function(response) {
            currentObj.output = response.data;
            currentObj.output = "Your request has been sent successfully";
            currentObj.stat = "success";
          })
          .catch(function(error) {
            currentObj.stat = "error";
            // currentObj.output = error;
            currentObj.output = "This mail is already subscribed OR " + error;
            // console.log("faild");
          });
        this.$notify({
          group: "foo",
          type: this.stat,
          title: "Important message",
          text: this.output
        });
      } else {
        this.$notify({
          group: "foo",
          type: "error",
          title: "Wrong message",
          text: "Please stop sending, your request has already been sent"
        });
      }
    },
    getSetting() {
      this.loading = true;
      this.$urlmain
        .get("settings.json")
        .then(response => {
          this.settings = response.data.data;
          response => (this.settings = response.data.data);
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    },
    getfooterpages() {
      this.loading = true;
      this.$urlmain
        .get("pages-footer.json")
        .then(response => {
          this.pages = response.data.data;
          response => (this.pages = response.data.data);
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
