<template>
  <div id="app">
    <HeaderComp />
    <router-view />
    <FooterComp />
  </div>
</template>

<script>
// @ is an alias to /src

import HeaderComp from "@/components/units/HeaderComp.vue";
import FooterComp from "@/components/units/FooterComp.vue";

export default {
  name: "home",
  components: {
    HeaderComp,
    FooterComp
  }
};
</script>
