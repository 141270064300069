<template>
  <div class="container">
    <div class="row logo_mobile_phones">
      <div class="col-12 p-0">
        <nav class="navbar navbar-expand-lg navbar-light bg-light row justify-content-between">
          <router-link to="/" tag="a" class="navbar-brand col-md-2">
            <img :src="
                'https://app.contractsexpert.co.uk/storage/app/public/' +
                  settings.logo_head
              "
              :alt="settings.name"
              width="190"
              style="height: 4rem;"
            />
          </router-link>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse col-lg-8" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto row justify-content-center" style="position: relative;">
              <li class="nav-item dropdown" v-for="(info, i) in informations" :key="i" style="position: relative">
                <router-link :to="'/categories/' + info.slug" tag="a"
                  v-bind:class="[ info.products.length !== 0 ? 'dropdown-toggle' : '' ]"
                  class="nav-link" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{ info.title }}
                </router-link>
                <ul class="dropdown-menu mega-dropdown-menu mega_edit" aria-labelledby="menu-4">
                  <li class="row">
                    <ul class="col-lg-12" >
                      <template v-for="info2 in info.products">
                        <li v-for="(info3, i) in info2.offers" :key="info2.title+i" v-show="info3.nav == 1">
                          <router-link :to="'/categories/' + info.slug + '/'+info2.brand.slug+'/'+info2.slug+'?product='+info2.id" tag="a" class="dropdown-item">{{info3.title}}</router-link>
                        </li>
                      </template>
                    </ul>
                    <!-- <ul class="col-lg-3 col-xs-4 col-sm-4" v-if="info.id!==1 && info.id!==3">
                      Brand
                      <li v-for="(infoB, i) in informations2" :key="i">
                        <router-link :to="'/categories/'+info.slug+'/'+infoB.slug" tag="a" class="dropdown-item">{{infoB.title}}</router-link>
                      </li>
                    </ul>
                    <ul class="col-lg-3 col-xs-4 col-sm-4">
                      Network
                      <li v-for="(infoN, i) in informations3" :key="i" >
                        <router-link :to="'/networks/'+infoN.slug+'?network='+infoN.id" tag="a" class="dropdown-item">{{infoN.title}}</router-link>
                      </li>
                    </ul> -->
                  </li>
                </ul>
                <!-- <div class="dropdown-menu" aria-labelledby="navbarDropdown" v-if="info.products.length !== 0">
                  <template v-for="info2 in info.products">
                      <template v-for="(info3, i) in info2.offers" v-show="info3.nav == 1" >
                        <router-link :key="info2.title + i"
                          :to="
                            '/categories/' +
                              info.slug +
                              '/' +
                              info2.brand.slug +
                              '/' +
                              info2.slug +
                              '?product=' +
                              info2.id
                          "
                          tag="a"
                          class="dropdown-item"
                          >
                          {{ info3.title }}
                          </router-link>
                      </template>
                    </template>
                </div> -->
              </li>
              <li class="nav-item dropdown mega-dropdown">
                <router-link to="/guides?all_blogs" tag="a" class="nav-link dropdown-toggle"
                id="menu-7"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">Guides</router-link>
                <ul class="dropdown-menu mega-dropdown-menu mega_edit" aria-labelledby="menu-7">
                  <li class="row">
                    <template v-for="guides in blogCategory" >
                    <div class="col-lg-12" :key="guides.id + i"  v-if="guides.Blogs.length !== 0">
                      <li>
                        <!-- <router-link :to="'/guides?category='+ guides.slug"> {{guides.title}} </router-link> -->
                        <ul v-if="guides.Blogs.length !== 0">
                          <template v-for="guidesB in guides.Blogs">
                            <li :key="guidesB.id + i" class="dropdown-item">
                              <router-link :to="'/guides/'+ guidesB.id" tag="a">{{guidesB.title}}</router-link>
                            </li>
                          </template>
                        </ul>
                      </li>
                    </div>
                    </template>
                  </li>
                </ul>
              </li>
              <!-- <li class="compare">
                  <router-link to="/compare" tag="a" class="ha-toggle">
                    <span class="lnr lnr-sync"></span>
                    <span class="count">{{this.icon}}</span>
                    Compare
                  </router-link>
                </li> -->
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HeaderComp",
  data() {
    return {
      icon: 0,
      settings: [],
      informations: [],
      informations2: [],
      informations3: [],
      blogCategory: []
    };
  },
  mounted() {
    this.compareIcon();
    this.getSetting();
    this.getblogCategory();

    this.loading = true;
    this.$urlmain
      .get("category-nav.json")
      .then(response => {
        this.informations = response.data.data;
        response => (this.informations = response.data.data);
      })
      .catch(error => console.log(error))
      .finally(() => (this.loading = false));
  },

  computed: {
    getFullPath() {
      return this.$route.path;
    }
  },
  watch: {
    getFullPath() {
      this.compareIcon();
    }
  },
  methods: {
    compareIcon() {
      this.icon = 0;
      if (window.$cookies.get("pro1") != null) this.icon++;
      if (window.$cookies.get("pro2") != null) this.icon++;
      if (window.$cookies.get("pro3") != null) this.icon++;
    },
    getSetting() {
      this.loading = true;
      this.$urlmain
        .get("settings.json")
        .then(response => {
          this.settings = response.data.data;
          response => (this.settings = response.data.data);
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    },
    getblogCategory() {
      this.loading = true;
      this.$urlmain
        .get("blogCategory-nav.json")
        .then(response => {
          this.blogCategory = response.data.data;
          response => (this.blogCategory = response.data.data);
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    },
    getBrands(){
      this.$urlmain
      .get("brands-nav.json")
      .then(response => {
        this.informations2 = response.data.data;
        response => (this.informations2 = response.data.data);
      })
      .catch(error => console.log(error))
      .finally(() => (this.loading = false));
    },
    getNetworks(){
      this.$urlmain
      .get("network-nav.json")
      .then(response => {
        this.informations3 = response.data.data;
        response => (this.informations3 = response.data.data);
      })
      .catch(error => console.log(error))
      .finally(() => (this.loading = false));
    },
  },
  created() {}
};
</script>
